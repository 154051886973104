body {
  @include font-smoothing;
}

.store-navbar {
  padding: 30px 0 0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15);
  z-index: 1999;
  position: relative;

  .section-top {
    border-bottom: 1px solid #E7E7E7;
    padding-bottom: 25px;

    @media(max-width: 991px) {
      text-align: center;
      border-bottom: 0;
      padding-bottom: 15px;
    }

    .logo {
      color: #454545;
      text-decoration: none;
      position: relative;
      top: -2px;
      font-size: 26px;
      font-weight: 400;
      letter-spacing: 2px;

      @media(max-width: 991px) {
        font-size: 22px;
        float: none !important;
        display: block;
      }
    }

    .menu-right {
      position: relative;
      top: 5px;
      font-size: 14px;

      @media(max-width: 991px) {
        padding: 0;
        float: none !important;
        margin-top: 15px;
      }

      li {
        display: inline-block;
        margin-left: 35px;
        position: relative;

        @media(max-width: 991px) {
          margin: 0 15px;
        }

        @media(max-width: 767px) {
          margin: 0 6px;
        }
      }
    }

    .cart {
      text-decoration: none;
      color: #a6a6a6;
      cursor: pointer;

      @include transition(all .35s);

      &:hover {
        color: #222;
      }

      i {
        font-size: 20px;
        position: relative;
        top: 2px;
        margin-right: 3px;
      }

      span {
        margin-left: 3px;
        font-weight: 500;
        position: relative;
      }
    }

    #cart-modal {
      font-family: $lato;
      position: absolute;
      background: #fff;
      width: 480px;
      right: -65px;
      top: 40px;
      z-index: 999;
      border-radius: 5px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.39);
      opacity: 0;
      visibility: hidden;

      @include transition(all .2s);

      @media(max-width: 991px) {
        display: none;
      }

      &.visible {
        opacity: 1;
        visibility: visible;
      }

      .pointer {
        position: absolute;
        top: -22px;
        right: 80px;

        .arrow {
          border-color: transparent transparent #fff transparent;
          border-width: 11px;
          border-style: solid;
          position: absolute;
          top: 1px;
        }

        .arrow_border {
          border-color: transparent transparent rgba(0, 0, 0, 0.12);
          border-width: 11px;
          border-style: solid;
        }
      }

      .products {
        text-align: left;

        .product {
          display: table;
          width: 100%;
          padding: 17px 20px;
          border-bottom: 1px solid #E8E8E8;

          .pic {
            display: table-cell;
            vertical-align: middle;
            width: 90px;

            img {
              max-width: 85px;
            }
          }

          .details {
            display: table-cell;
            width: 200px;
            padding: 0 18px;
            vertical-align: middle;

            .name {
              font-weight: 600;
              color: #444;
              text-decoration: none;

              @include transition(all .2s);

              &:hover {
                color: #222;
              }
            }

            .vendor {
              display: block;
              font-style: italic;
              font-family: $georgia;
              color: #888;
            }
          }

          .qty {
            display: table-cell;
            vertical-align: middle;
            width: 60px;
            text-align: center;

            a {
              display: block;
              color: #C0C0C0;

              @include transition(all .2s);

              &:hover {
                color: #444;
              }
            }

            span {
              display: block;
              font-weight: 600;
              font-size: 15px;
              margin: 2px 0;
            }
          }

          .subtotal {
            display: table-cell;
            vertical-align: middle;
            text-align: right;
            padding-right: 10px;

            span {
              font-size: 15px;
              font-weight: 600;
            }
          }
        }
      }

      .total-price {
        padding: 20px 30px 0 20px;

        .note {
          float: left;
          font-size: 13px;
          margin-left: 10px;
          font-family: $georgia;
          font-style: italic;
          color: #666;
        }

        .total {
          float: right;
          font-size: 20px;
          font-weight: 600;
          position: relative;
          top: -4px;

          span {
            margin-right: 25px;
            font-size: 14px;
            font-weight: normal;
          }
        }
      }

      .cta {
        text-align: right;
        padding: 20px 25px 20px;

        .go-cart {
          float: left;
          text-transform: uppercase;
          text-decoration: none;
          color: #555;
          border: 1px solid #D7D7D7;
          font-weight: 600;
          border-radius: 50px;
          display: inline-block;
          padding: 10px 22px;
          font-size: 10px;
          letter-spacing: 1.3px;
          font-family: $helvetica;

          @include transition(all .2s);

          &:hover {
            border-color: #B2B2B2;
          }
        }

        .go-checkout {
          float: right;
          text-transform: uppercase;
          text-decoration: none;
          color: #fff;
          font-weight: 600;
          background: #4892C7;
          border-radius: 50px;
          display: inline-block;
          padding: 10px 22px;
          font-size: 10px;
          letter-spacing: 1.3px;
          font-family: $helvetica;

          @include transition(all .2s);

          &:hover {
            background: #45A3E7;
          }
        }
      }
    }

    .search-field {
      position: relative;
      width: 140px;

      @media(max-width: 991px) {
        text-align: left;
        width: 120px;
      }

      &.focus {
        i {
          color: #333;
        }
      }

      i {
        font-size: 20px;
        top: 2px;
        left: 0;
        color: #afafaf;
        z-index: 1;
        position: relative;

        @include transition(all .2s);
      }

      .input-search {
        position: absolute;
        width: 100%;
        top: -1px;
        left: 0;
        height: 33px;
        outline: none;
        padding: 0px 0 0 25px;
        border: none;
      }
    }

    .account {
      color: #a6a6a6;
      font-weight: 500;
      text-decoration: none;

      @include transition(all .35s);

      &:hover {
        color: #222;
      }

      i {
        font-size: 17px;
        position: relative;
        top: 1px;
        margin-right: 3px;
      }
    }
  }

  .section-menu {
    .navbar-toggler {
      position: absolute;
      right: 0;
      top: -120px; 
    }

    .navbar {
      padding: 0;
    }

    .nav-item {
      @media(max-width: 991px) {
        &:last-child {
          .nav-link {
            padding-bottom: 20px;
          }
        }
      }

      &.show {
        @media(max-width: 991px) {
          .nav-link {
            color: #222;
          }
        }

        @media(min-width: 992px) {
          .nav-link {
            background: #222 !important;
            color: #fff !important;
          }
        }
      }

      .dropdown-extend-menu {
        background: #222;
        padding: 35px 50px;

        @media(max-width: 991px) {
          background: #fff;
          padding: 15px 30px;
        }
          
        h3 {
          color: #fff;
          font-size: 14px;
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: 600;
          padding-bottom: 15px;
          border-bottom: 1px solid #454545;
          margin-bottom: 20px;

          @media(max-width: 991px) {
            display: none;
          }
        }

        a {
          color: #e8e8e8;
          text-decoration: none;
          display: block;
          margin: 8px 0;

          @include transition(all .1s linear);

          @media(max-width: 991px) {
            color: #444;
          }

          @media(min-width: 992px) {
            &:hover {
              color: #fff;
            }
          }
        }
      }
    }

    .nav-link {
      letter-spacing: 1px;
      font-size: 13px;
      padding: 0.7em 1em;
      text-transform: uppercase;

      @include transition(all .15s);

      @media(min-width: 992px) {
        padding: 25px 30px;
      }

      .ion-chevron-down {
        font-size: 10px;
        position: relative;
        top: -1px;
        margin-left: 5px;
      }
    }

    .dropdown-menu {
      margin-top: 0;

      @include transition(all .15s);
      @include transform(none);

      @media(min-width: 992px) {
        background: #222;
        border-radius: 0 0 4px 4px;
      }

      .dropdown-item {
        @media(min-width: 992px) {
          color: #fff;
          padding: 9px 60px 9px 30px;

          @include transition(all .2s);

          &:hover,
          &:focus {
            background: #333;
          }
        }
      }
    }

    .dropdown-submenu {
      .dropdown-toggle i {
        @media(min-width: 992px) {
          top: 13px;
        }
      }
    }
  }
}

.store-footer {
  margin-top: 100px;
  background: #F8F8F9;
  padding: 50px 0 40px;
  border-top: 1px solid #ddd;

  .col-md-4 {
    padding-left: 35px;

    @media(max-width: 767px) {
      margin-bottom: 30px;
      padding-left: 20px;
    }
  }

  .title {
    color: #333;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
  }

  .menu {
    list-style-type: none;
    padding: 0;
    margin-top: 20px;

    a {
      color: #6B7EA8;
      display: inline-block;
      margin-bottom: 9px;
      text-decoration: none;

      @include transition(all .2s linear);

      &:hover {
        color: #333;
      }
    }

    i {
      color: #7994B3;
      font-size: 23px;
      margin-right: 12px;
      width: 21px;
      position: relative;
      top: 3px;
      margin-bottom: 5px;
    }
  }

  .newsletter {
    p {
      color: #777;
      margin-top: 15px;
      line-height: 20px;
    }

    form {
      margin-top: 27px;
      position: relative;

      input[type="email"] {
        height: 45px;
        padding-left: 17px;

        @include placeholder {
          color: #999;
        }

        &:focus {
          box-shadow: none;
        }
      }

      button {
        border: 0;
        position: absolute;
        top: 0;
        right: 0;
        font-size: 17px;
        height: 45px;
        background: #54A7EC;
        padding: 0 15px;
        border-radius: 0 4px 4px 0;
        color: #fff;
      }
    }
  }

  .bottom {
    margin-top: 40px;
    border-top: 1px solid #D9DFEA;

    span {
      display: inline-block;
      margin-top: 18px;
      color: #555;
    }

    .payment-methods {
      float: right;
      position: relative;
      top: 17px;

      img {
        max-width: 40px;
        margin-left: 5px;
      }
    }
  }
}
